<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5></h5>
            <div class="ibox-tools">
              <router-link to="addApi">
                <i class="fa fa-plus"></i> &nbsp;&nbsp; Add
              </router-link>
            </div>
          </div>
          <div class="ibox-content">
            <div class="table-responsive">
              <table class="table table-bordered table-hover" :class="dataTablesApi">
                <thead>
                  <tr>
                    <th>{{ apiColumn.id }}</th>
                    <th>{{ apiColumn.name }}</th>
                    <th>{{ apiColumn.description }}</th>
                    <th>{{ apiColumn.type }}</th>
                    <th>{{ apiColumn.method }}</th>
                    <th>{{ apiColumn.serviceProvider }}</th>
                    <th>{{ apiColumn.endpoint }}</th>
                    <th>{{ apiColumn.modifiedBy }}</th>
                    <th>{{ apiColumn.modifiedDate }}</th>
                    <th>{{ apiColumn.action }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="gradeX" v-for="(value, key) in apiData" :key="key">
                    <td style="width: 10%">{{ value.id }}</td>
                    <td style="width: 25%">{{ value.name }}</td>
                    <td style="width: 30%">{{ value.description }}</td>
                    <td style="width: 10%">{{ value.type }}</td>
                    <td style="width: 10%">{{ value.method }}</td>
                    <td style="width: 15%">{{ value.serviceProvider }}</td>
                    <td style="width: 15%">{{ value.endpoint }}</td>
                    <td style="width: 15%">{{ value.modifiedBy }}</td>
                    <td style="width: 15%">{{ value.modifiedDate }}</td>
                    <td style="width: 2%">
                      <i class="fas fa-user-cog"></i>
                      <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                        <i class="fa fa-wrench"></i>
                      </a>
                      <ul class="dropdown-menu dropdown-user">
                        <li>
                          <router-link
                            :to="{
                              path: '/updateApi',
                              query: { id: value.id },
                            }"
                            class="dropdown-item"
                            >Edit</router-link
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#myModal4"
                            @click="addModalData(value)"
                            >Delete</a
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>{{ apiColumn.id }}</th>
                    <th>{{ apiColumn.name }}</th>
                    <th>{{ apiColumn.description }}</th>
                    <th>{{ apiColumn.type }}</th>
                    <th>{{ apiColumn.method }}</th>
                    <th>{{ apiColumn.serviceProvider }}</th>
                    <th>{{ apiColumn.endpoint }}</th>
                    <th>{{ apiColumn.modifiedBy }}</th>
                    <th>{{ apiColumn.modifiedDate }}</th>
                    <th>{{ apiColumn.action }}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <DeleteModal :data="data"></DeleteModal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import DataTableService from "../plugin/DataTableService";
import DeleteModal from "../components/common/DeleteModal";
import EventBus from "../plugin/event-bus";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      apiColumn: {
        id: "Id",
        name: "Api Name",
        description: "Description",
        type: "Type",
        method: "Method",
        serviceProvider: "Service Provider",
        endpoint: "Endpoint",
        action: "Action",
        modifiedBy: "Updated By",
        modifiedDate: "Updated Date"
      },
      apiData: [],
      dataTablesApi: "dataTables-Api",
      exportTitle: "Group Details",
      data: {
        title: "API",
        name: "",
        id: 0,
        url: Constant.url.API_DELETE,
      },
      selectedApi: {},
    };
  },
  components: {
    DeleteModal,
  },
  created: function () {
    this.fetchapiData();
    this.deleteEvent();
  },
  mounted: function () {
    var self = this;
  },
  methods: {
    fetchapiData: function () {
      var self = this;
      var responseCode = Constant.data.RESPONSE_CODE;
      AxiosService.get(Constant.url.API_ALL).then(async (result) => {
        self.apiData = result;
        await setTimeout(async () => {
           await DataTableService.loadDataTable(self.dataTablesApi, self.exportTitle);
        }, Constant.timeout);
      });
    },
    addModalData: function (value) {
      this.selectedApi = value;
      this.data.name = value.name;
      this.data.id = value.id;
    },
    deleteEvent: function () {
      const self = this;
      EventBus.$on(Constant.data.DELETE_EVENT, (data) => {
        if (data.responseCode == Constant.data.SUCCESS) {
          AuditListner.addAuditLog(
            AuditListner.initalizedData(
              Constant.data.API_DEFINITION_ACTIVITY,
              Constant.data.DELETE_TITLE,
              AuditListner.apiDefinitionTemplate(self.selectedApi)
            )
          );
          self.fetchapiData();
          DataTableService.reLoadDataTable(self.dataTablesApi);
          DataTableService.loadDataTable(self.dataTablesApi, self.exportTitle);
        }
      });
    },
  },
  beforeDestroy() {
    EventBus.$off(Constant.data.DELETE_EVENT);
  },
};
</script>
<style scoped>
.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #0f0f0f !important;
}
a {
  color: #636363;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
</style>
