<template>
  <div
    class="modal inmodal"
    id="myModal4"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content animated fadeIn">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span
            ><span class="sr-only">Close</span>
          </button>
          <i class="fa fa-trash-o modal-icon" style="text-align: center;"></i>
          <h4>Delete {{ data.title }}</h4>
          <br />
          <h4>Are you sure do you want to delete {{ data.name }} ?</h4>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-white"
            data-dismiss="modal"
            id="closeBtn"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="deleteCustomer(data.id)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../../plugin/AxiosService.js";
import Constant from "../../plugin/Constant.js";
import EventBus from "../../plugin/event-bus";

export default {
  props: {
    data: Object,
  },
  mounted: function() {},
  methods: {
    deleteCustomer: function(id) {
      var self = this;
      AxiosService.post(this.data.url + id).then((result) => {
        EventBus.$emit(Constant.data.DELETE_EVENT, result);
        $("#closeBtn").click();
      });
    },
  },
};
</script>

<style scoped></style>
